let DUMMY_DEFAULT_TRANSACTION = {"_id":"6432eefd18c3dc237e5dff52", "damageId":"6432705e16458a03e3b3e5ac", "offerId":"6432edf273411a23127e8e1b", "paymentDetails":{     "paymentId":"0E414522SP7408819",     "captureStatus":"COMPLETED",     "authorizationId":"2U049495ER6076131",     "orderId":"74F063574B247094M" }, "customerInvoiceId":"6432ef2718c3dc237e5dff58", "garageInvoiceId":"6432ef2718c3dc237e5dff59", "createdAt":new Date("2022-02-03T21:08:12.920Z")};
let DUMMY_DEFAULT_INVOICE = {"invoiceIdPrefix":"C","invoiceId":1000000001,"type":"INVOICE_TYPE_CUSTOMER","createdAt":new Date("2022-02-03T21:08:12.920Z")};
let DUMMY_DEFAULT_DAMAGE = {"_id": "6432705e16458a03e3b3e5ac", "carManufacturer":"FSM","carModel":"POLSKI FIAT 126 P","carYear":2018,"carHsn":"9206","carTsn":"AB123","carFin":"ABC123123123","damages":[{"damageType":"DAMAGE_TYPE_RIM"}],"repairType":"REPAIR_TYPE_PROFESSIONAL","requestType":"REQUEST_TYPE_STANDARD","price":670,"locationName":"81677 Munich, Germany"};
let DUMMY_DEFAULT_OFFER = {"_id": "6432edf273411a23127e8e1b", "status":"OFFER_STATUS_ACCEPTED","notice":"","price":266,"durationDays":12,"type":"OFFER_TYPE_COUNTER"};
let DUMMY_DEFAULT_GARAGE = {"firstname":"","lastname":"","accountType":"ACCOUNT_TYPE_GARAGE","garage":{"address":"80796 Munich, Germany","location":{"type":"Point","coordinates":[11.5691057,48.1630201]},"name":"New Orleans Customzz","placeId":"ChIJ_4pBAdt1nkcRcJl2O6clHRw","phone":"+49 1515 7307440"},"email":"info@new_orleans_customzz.fancy"};
let DUMMY_DEFAULT_CUSTOMER = {"_id":"1411edf273411a11127e9e1c","firstname":"Michael","lastname":"Jackson","accountType":"ACCOUNT_TYPE_CUSTOMER","email":"customer01@test.com"};

const SERVICE_FEE = 0.13;

const DAMAGE_TYPES = {
    dent: "DAMAGE_TYPE_DENT",
    bump: "DAMAGE_TYPE_BUMP",
    scratch: "DAMAGE_TYPE_SCRATCH",
    polish: "DAMAGE_TYPE_POLISH",
    rim: "DAMAGE_TYPE_RIM"
};

// eslint-disable-next-line no-unused-vars
let computedData = (transaction, invoice, damage, offer, garage, customer) => {
    let repairTypeMapping = {
        "REPAIR_TYPE_PROFESSIONAL": "Professional",
        "REPAIR_TYPE_LEASING": "Leasing",
        "REPAIR_TYPE_SMART": "Smart-Repair",
    };
    let parseLength = (len) => {
        len = `${len}`;
        return `${
            len.match(/\+$/) ? `>${len.substr(0, len.length - 1)}` : len
        }cm`;
    };
    let damageItem = (item) => {
        switch (item.damageType) {
            case DAMAGE_TYPES.dent:
            return `Delle (${parseLength(item.size)})`;

            case DAMAGE_TYPES.scratch:
            return `${
                item.isDeep ? "Tiefer Kratzer" : "Oberflächlicher Kratzer"
            } (${parseLength(item.length)})`;

            case DAMAGE_TYPES.bump:
            return `Beule (${parseLength(item.size)})`;

            case DAMAGE_TYPES.rim:
            return `Felgen-Schaden`;

            case DAMAGE_TYPES.polish:
            return `Polieren`;

            default:
            return null;
        }
    };

    const priceGross = (offer.price).toFixed(2);
    const priceNet = (offer.price / 1.19).toFixed(2);
    const tax = (offer.price - priceNet).toFixed(2);

    const feeGross = (priceGross * SERVICE_FEE).toFixed(2);
    const feeNet = (feeGross / 1.19).toFixed(2);
    const feeTax = (feeGross - feeNet).toFixed(2);

    const payoutGross = (priceGross - feeGross).toFixed(2);

    const dueDate = new Date(invoice.createdAt);
    dueDate.setDate(invoice.createdAt.getDate()+14);
    return {
        repairType: repairTypeMapping[damage['repairType']],
        damages: damage.damages.map(damageItem).join(", "),
        priceNet,
        priceGross,
        tax,
        feeNet,
        feeGross,
        feeTax,
        payoutGross,
        invoiceId: invoice.invoiceIdPrefix + invoice.invoiceId.toString(),
        customerId: customer._id.toUpperCase(),
        date: invoice.createdAt.toLocaleDateString("de-DE", { year: 'numeric', month: '2-digit', day: '2-digit' }),
        dueDate: dueDate.toLocaleDateString("de-DE", { year: 'numeric', month: '2-digit', day: '2-digit' }),
    }
};

export const payloads = [
  {
    key: "default",
    payload: {
      transaction: DUMMY_DEFAULT_TRANSACTION,
      invoice: DUMMY_DEFAULT_INVOICE,
      damage: DUMMY_DEFAULT_DAMAGE,
      offer: DUMMY_DEFAULT_OFFER,
      garage: DUMMY_DEFAULT_GARAGE,
      customer: DUMMY_DEFAULT_CUSTOMER,
      computed: computedData(DUMMY_DEFAULT_TRANSACTION, DUMMY_DEFAULT_INVOICE, DUMMY_DEFAULT_DAMAGE, DUMMY_DEFAULT_OFFER, DUMMY_DEFAULT_GARAGE, DUMMY_DEFAULT_CUSTOMER),
    }
  },
];
