<template>
    <div id="editor" style="width: 100%;"></div>
</template>

<script>
import loader from "@monaco-editor/loader";

export default {
  name: "Code",
  props: ["value", "onValueChange", "language"],
  data: () => ({
    editor: null,
    model: null,
    subscription: null,
  }),
  async mounted() {
    const { editor, model } = await loader.init().then((monaco) => {
      const editor = monaco.editor.create(document.getElementById("editor"), { automaticLayout: true });
      const model = editor.getModel();
      monaco.editor.setModelLanguage(model, this.language);
      return { editor, model };
    });
    this.editor = editor;
    this.model = model;
    this.subscription = this.model.onDidChangeContent(() => {
      this.onValueChange(this.model.getValue());
    });
  },
  watch: {
    value() {
      this.model.pushEditOperations(
        [],
        [
          {
            range: this.model.getFullModelRange(),
            text: this.value,
          },
        ]
      );
    },
  },
  beforeDestroy() {
    this.subscription.dispose();
  },
};
</script>

<style lang="scss" scoped>
</style>