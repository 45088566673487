<template>
  <div class="page">
    <div class="top-bar">
      <div class="headline">
        <h1>Manage <b>Templates</b></h1>
      </div>
    </div>
    <div :class="{ isFetching, maximized }">
      <div class="editor-headbar">
        <div></div>
        <div class="right-buttons">
          <el-button :disabled="isProd" @click="() => saveTemplatePrompt(false)" type="primary" round>Save</el-button>
          <el-button :disabled="isProd" @click="() => saveTemplatePrompt(true)" type="primary" round>Save All</el-button>
          <button class="fullscreen-button" @click="toggleFullscreen">
            <fa-icon v-if="!maximized" icon="expand" />
            <fa-icon v-else icon="compress" />
          </button>
        </div>
      </div>
      <div class="editor-wrap">
        <div class="templates">
          <div v-for="group in Object.keys(templateKeyGroups)" :key="group">
            <span>{{group}}</span>
            <div :class="{ template: 1, active: currentTemplateName === `${group}_${template}` }" v-for="template in templateKeyGroups[group]" :key="template" @click="() => changeTemplate(`${group}_${template}`)">
              {{template}}
            </div>
          </div>
        </div>
        <div v-if="mode == 'template'" class="editor">
          <Code :value="currentContent" :onValueChange="updateValue" language="handlebars" />
        </div>
        <div v-if="mode == 'payload'" class="payload-editor">
          <Code :value="payloadContentString" :onValueChange="onUpdatePayload" language="json" />
        </div>
        <div class="preview">
          <div class="buttons">
            <div class="template-select">
              <el-select size="small" v-model="currentPayload" placeholder="Select payload">
                <el-option v-for="payload in payloads" :key="payload.key" :label="payload.key" :value="payload.key"></el-option>
              </el-select>
              <el-button :disabled="thumbLoading" class="refresh-button" @click="toggleMode" type="text">
                <fa-icon v-if="mode == 'template'" icon="cogs" />
                <fa-icon v-if="mode == 'payload'" icon="check" />
              </el-button>
            </div>
            <el-button :disabled="thumbLoading" class="refresh-button" @click="generateThumb" type="text">Refresh</el-button>
          </div>
          <img :src="thumbImage" v-if="thumbImage && !thumbLoading" />
          <span v-if="!thumbImage || thumbLoading">{{ thumbLoading ? "Generating ..." : "No Thumb-Image" }}</span>
        </div>
      </div>
      <div>
        <div class="editor-bottombar">
          <div></div>
          <div>
            <el-button @click="exportPdf" type="text">Export PDF</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SAVE_TEMPLATE, PUBLISH_TEMPLATE, GET_TEMPLATE, UPDATE_CACHE_TEMPLATE } from '@/vuex/modules/template/actions'
import constants from '@/constants'
import apiService from "@/services/api";
import Code from "@/components/Code/Code.vue";
import { payloads } from "@/constants/dummyData";

const downloadFile = (fileName, contents, type = "text/plain") => {
  const element = document.createElement("a");
  const file = contents instanceof Blob ? contents : new Blob([contents], {
    type,
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
};

export default {
  name: 'TemplatesCode',
  components: {
    Code,
  },
  data() {
    return {
      mode: "template",
      templateKeyGroups: constants.templates,
      currentTemplateName: "",
      dirty: false,
      autosave: false,
      savePromptOpen: false,
      maximized: false,
      thumbImage: null,
      thumbLoading: false,
      currentPayload: "default",
      payloads,
    };
  },
  computed: {
    ...mapState({
      currentTemplateKey: state => state.template.currKey,
      currentContent: state => state.template.currContent,
      isFetching: state => state.template.isFetching,
      env: state => state.settings.env
    }),
    payloadContent() {
      return this.payloads.find(p => p.key === this.currentPayload).payload;
    },
    payloadContentString() {
      return JSON.stringify(this.payloadContent, null, 2);
    },
    isProd() {
      return this.env === "production";
    }
  },
  watch: {
    currentContent() {
      if (!this.currentContent) {
        window.document.querySelector("#editor").classList.add("loading");
        return;
      }

      window.document.querySelector("#editor").classList.remove("loading");
      const content = JSON.parse(this.currentContent);
      window.unlayer.loadDesign(content);
    },
    env() {
      this.changeTemplate(this.currentTemplateKey);
    }
  },
  mounted() {
    this.prefetchAllTemplates();
  },
  methods: {
    async updateValue(content) {
      await this.$store.dispatch(UPDATE_CACHE_TEMPLATE, {
        key: this.currentTemplateKey, 
        content,
      });
    },

    saveTemplatePrompt(saveAll) {
      this.savePromptOpen = true;
      this.$prompt('What has changed?', 'Save', {
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel',
      }).then(async ({ value }) => {
        this.savePromptOpen = false;
        if (saveAll) {
          for (const group of Object.keys(constants.templates)) {
            for (const key of this.templateKeyGroups[group]) {
              const template = group + "_" + key;
              await this.saveTemplate(template, value, true)
            }
          }
        } else {
          this.saveTemplate(this.currentTemplateName, value, true);
        }
      }).catch(() => {
        this.savePromptOpen = false;
      });
    },

    async saveTemplate(templateKey, message, showNotification = true) {
      if (!message) {
        message = "No message";
      }

      await this.$store.dispatch(SAVE_TEMPLATE, { key: templateKey, message });

      this.dirty = false

      if (showNotification) this.$message({
        type: 'success',
        message: 'Template saved!'
      });
    },

    async publishTemplate() {
      await this.$store.dispatch(PUBLISH_TEMPLATE);

      this.dirty = false

      this.$message({
        type: 'success',
        message: 'Template published!'
      });
    },

    async prefetchAllTemplates() {
      for (const group of Object.keys(constants.templates)) {
        for (const key of this.templateKeyGroups[group]) {
          const template = group + "_" + key;
          console.log(template);
          await this.$store.dispatch(UPDATE_CACHE_TEMPLATE, { key: template, content: (await apiService.getTemplate(template)).content });
        }
      }
    },

    changeTemplate(templateKey) {
      this.currentTemplateName = templateKey;
      this.$store.dispatch(GET_TEMPLATE, templateKey);
    },

    exportHTML() {
      downloadFile(this.currentTemplateKey + ".html", this.currentContent, "application/xhtml+xml");

      this.$message({
        type: 'success',
        message: 'Template exported'
      });
    },

    toggleFullscreen() {
      this.maximized = !this.maximized;
    },

    onUpdatePayload() {
      let payloadData;
      try {
        payloadData = JSON.parse(this.payloadContent);
      } catch (error) {
        return;
      }
      this.payloads = this.payloads.map(p => {
        if (p.key === this.currentPayload) {
          return {
            ...p,
            payload: payloadData,
          }
        }
        return p;
      });
    },

    async exportPdf() {
      const pdf = await apiService.exportTemplate(this.currentContent, this.payloadContent, "pdf");
      downloadFile(this.currentTemplateKey + ".pdf", pdf, "application/pdf");

      this.$message({
        type: 'success',
        message: 'Template exported'
      });
    },

    async generateThumb() {
      this.thumbLoading = true;
      try {
        const image = await apiService.exportTemplate(this.currentContent, this.payloadContent, "thumb");
        const urlCreator = window.URL || window.webkitURL;
        this.thumbImage = urlCreator.createObjectURL(image);
      } finally {
        this.thumbLoading = false;
      }
    },

    toggleMode() {
      if (this.mode == "template") {
        this.mode = "payload";
      } else {
        this.mode = "template";
      }
    }

  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: normal;
  margin: 0 30px 0 0;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}

.headline {
  display: flex;
  align-items: center;
}

.keys {
  width: 142px;
  margin-right: 12px;
}

#editor {
  height: calc(100vh - 280px);
}

.loading {
  opacity: 0.4;
  filter: blur(2px);
}

.editor-headbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(230, 230, 230);
  border-radius: 12px 12px 0 0;
  padding: 4px 12px;
  height: 50px;
}

.editor-bottombar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(230, 230, 230);
  border-radius: 0 0 12px 12px;
  padding: 4px 12px;
  height: 35px;
}

.templates {
  background: rgb(187, 187, 187);
  padding: 12px;
}

.right-buttons {
  display: flex;
  align-items: center;
}

.templates .template {
  padding: 12px 18px;
  margin: 7px 0 9px 0;
  font-size: 14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: normal;
  color: rgb(68, 68, 68);
  border-radius: 9px
}

.templates .template:hover {
  background-color: rgb(228, 228, 228);
  cursor: pointer;
}

.templates .template.active {
  background-color: transparent;
  cursor: default;
  background: rgb(68, 68, 68);
  color: white;
}

.auto-save {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  margin-right: 24px;
}
.auto-save > div {
  padding-bottom: 2px;
}

.import-button {
  border: none;
  cursor: pointer;
  background: rgb(219, 219, 219);
  padding: 7px 12px;
  border-radius: 4px;
  margin: 0 4px;
}

.import-button:hover {
  background: rgb(238, 238, 238);
}

.fullscreen-button{
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #686868;
  background: transparent;
  border-radius: 4px;
  margin: 0 7px 0 12px;
}

.fullscreen-button:hover {
  color: #525252;
}

.export-button {
  border: none;
  cursor: pointer;
  color: #686868;
  background: transparent;
  padding: 7px 12px;
  border-radius: 4px;
  margin-right: 4px;
}

.export-button svg {
  margin-right: 3px;
}

.export-button svg * {
  fill: #686868;
}

.export-button:hover {
  background: rgb(238, 238, 238);
}

.isFetching {
  opacity: 0.5;
  pointer-events: none;
}

.maximized {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.editor-wrap {
  display: flex;
}

.maximized #editor {
  height: calc(100vh - 100px);
}

.maximized .editor-headbar, .maximized .editor-bottombar {
  border-radius: 0;
}

.preview {
  position: relative;
  width: 500px;
  background: rgb(211, 211, 211);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;

  .buttons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;

    .template-select {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  span {
    position: absolute;
    top: 45%;
    text-align: center;
    width: 100%;
    color: rgb(106, 106, 106);
  }

  img {
    object-fit: contain;
    width: 100%;
  }
}

.editor {
  flex-grow: 1;
}
</style>

